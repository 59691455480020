import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICompany } from '@shared/interfaces/company.interface';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public logo = String(
    `../../../../assets/images/logo/logo-dark.svg`
  );
  public logoMenu = String(
    `../../../../assets/images/logo_onsite.png`
  );

  public openCompaniesModal: Subject<ICompany> = new Subject();

  public isProduction = Boolean(this._commonEnvironments.IS_PRODUCTION);
  public isCollapsed = Boolean(true);
  public links: {
    id: number;
    items: { label: string; icon: string; url: string }[];
  }[] = [
    {
      id: 0,
      items: [
        {
          label: 'NAVBAR.LINKS.DASHBOARD',
          url: '/dashboard',
          icon: 'lnr-chart-bars'
        },
        // {
        //   label: 'NAVBAR.LINKS.ACTIVITIES',
        //   url: '/activities',
        //   icon: 'lnr-radar'
        // }
        // {
        //   label: 'NAVBAR.LINKS.REPORTS',
        //   icon: 'lnr-chart-bars',
        //   url: '/reports',
        // },
        {
          label: 'NAVBAR.LINKS.EVENTS',
          url: '/events',
          icon: 'lnr-calendar-full'
        },
        {
          label: 'NAVBAR.LINKS.CHECKLISTS',
          url: '/checklists',
          icon: 'lnr-clipboard-check'
        },
        {
          label: 'NAVBAR.LINKS.LOCATIONS',
          url: '/locations',
          icon: 'lnr-location'
        },
        {
          label: 'NAVBAR.LINKS.TEMPLATES',
          url: '/templates',
          icon: 'lnr-document2'
        },
        {
          label: 'NAVBAR.LINKS.ACTIONS',
          url: '/actions',
          icon: 'lnr-warning'
        },
        {
          label: 'NAVBAR.LINKS.CONTACTS',
          url: '/contacts',
          icon: 'lnr-contacts'
        }
      ]
    },
    {
      id: 1,
      items: [
        {
          label: 'NAVBAR.LINKS.USERS',
          url: '/users',
          icon: 'lnr-users'
        },
        {
          label: 'NAVBAR.LINKS.COMPANIES',
          url: '/companies',
          icon: 'lnr-apartment'
        },
        {
          label: 'NAVBAR.LINKS.EMPLOYEES',
          url: '/employees',
          icon: 'lnr-tie'
        },
        {
          label: 'NAVBAR.LINKS.EMAILS',
          url: '/emails',
          icon: 'lnr-envelope'
        }
      ]
    }
  ];

  public params: any = {
    environment: this._commonEnvironments.ENVIRONMENT
  };

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _router: Router
  ) {
    this.afterSubmit = this.afterSubmit.bind(this);
  }

  ngOnInit(): void {}

  public trackByFn(i): string {
    return i.id;
  }

  public openAddModal() {
    this.openCompaniesModal.next();
  }

  public afterSubmit(c: ICompany) {
    this._router.navigate(['/companies', c.id]);
  }
}
