import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { itemTypes, itemValues } from '@shared/helpers/types.helper';
import { IEnergyStarMeter } from '@shared/interfaces/energy-star-meter.interface';
import { IError } from '@shared/interfaces/error.interface';
import { IItemGroup } from '@shared/interfaces/item-group.interface';
import { IItemOption } from '@shared/interfaces/item-option.interface';
import { IItem } from '@shared/interfaces/item.interface';
import { IMeter } from '@shared/interfaces/meter.interface';
import { EnergyStarMetersService } from '@shared/services/energy-star-meters.service';
import { ItemGroupsService } from '@shared/services/item-groups.service';
import { ItemsService } from '@shared/services/items.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-modals-items',
  templateUrl: './modals.items.component.html'
})
export class ModalsItemsComponent
  extends ModalDirective<IItem>
  implements OnInit {
  @Input() public openModal: Subject<IItem>;

  public title = String('MODALS.ITEMS.ADD.TITLE');
  public description = String('MODALS.ITEMS.ADD.DESCRIPTION');
  public entry: FormGroup;

  public isButtonsDisabled = Boolean(false);
  public isOptionsAvailable = Boolean(false);
  public disableButtons = Boolean(false);

  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;

  public types: { value: number; label: string }[] = itemTypes;
  public yesNoDefaultValues: any[] = itemValues;

  public errors: IError[] = [];
  public itemGroups: IItemGroup[] = [];
  public energyStarMeters: IEnergyStarMeter[] = [];
  public propertyIds = '';
  public meterInfo: IMeter;
  public isLoading = false;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _items: ItemsService,
    private readonly _energyStarMeters: EnergyStarMetersService,
    private readonly _itemGroups: ItemGroupsService,
    private readonly _toasts: ToastService
  ) {
    super();
  }

  public get formArrayOptions(): FormArray {
    return this.entry.get('itemOptions') as FormArray;
  }

  ngOnInit(): void {
    this.createForm();
    this.getAllPropertyIds();

    this.openModal.subscribe((i: IItem) => {
      this.getItemGroups();

      if (i) {
        if (i.id) {
          this.title = 'MODALS.ITEMS.EDIT.TITLE';
          this.description = 'MODALS.ITEMS.EDIT.DESCRIPTION';
          this.deleteKey = 'BUTTONS.DELETE';
        } else {
          this.title = 'MODALS.ITEMS.ADD.TITLE';
          this.description = 'MODALS.ITEMS.ADD.DESCRIPTION';
          this.deleteKey = null;
        }

        if (i.propertyId) {
          this.getAllMetersByPropertyId(i.propertyId);
        } else {
          this.energyStarMeters = [];
        }

        (this.entry.get('itemOptions') as FormArray).clear();

        if (i.itemOptions?.length > 0) {
          i.itemOptions.map((o: any) => this.addItem('itemOptions', o));
        }

        this.entry.patchValue({
          ...i,
          integrateEnergyStar: i.propertyId ? true : false,
          itemGroup: {
            id: i.itemGroup
          }
        });
      } else {
        this.entry.patchValue({
          type: ''
        });
      }
    });
  }

  public changeType() {
    const type = this.entry.get('type').value;
    const optionsField = this.formArrayOptions as FormArray;

    // get rid of options
    optionsField.clear();
    optionsField.clearValidators();

    if (type === 6) {
      [0, 1].map(() => {
        this.addItem('itemOptions');
      });

      optionsField.setValidators([
        Validators.required,
        Validators.minLength(2)
      ]);
    }

    optionsField.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });
  }


  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /items/${id}`;

    this._items.delete(id).subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, url, res);

        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (!valid) {
      return;
    }

    this.entry.disable();

    let method = 'post';
    let url = `${method.toUpperCase()} /items`;
    if (value.id) {
      method = 'patch';
      url = `${method.toUpperCase()} /items/${value.id}`;
    }


    let meterInfoRequest$: Observable<IMeter | null>;

    if (value.meterId) {
      meterInfoRequest$ = this._energyStarMeters.getMeterInfo(value.meterId).pipe(
        tap((res: any) => {
          this._logger.info(this.constructorName, `GET /energystar/id/meter-info`, res);
          this.meterInfo = res;
          value = { ...value, meterType: res.meter.type, meterUnit: res.meter.unitOfMeasure };
        }),
        catchError((err) => {
          this._logger.error(this.constructorName, `GET /energystar/id/meter-info`, err);
          return of(null);
        })
      );
    } else {
      meterInfoRequest$ = of(null);
    }

    meterInfoRequest$
      .pipe(
        switchMap(() => this._items[method](value))
      )
      .subscribe(
        (res: IItem) => {
          this._logger.info(this.constructorName, url, res);
          this.resetModal();
          this.entry.enable();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          const errors = err.errors;
          if (errors) {
            this.errors = errors;
            if (errors[0].detail) {
              this._toasts.error(errors[0].detail);
            }
          }

          this.entry.enable();
        }
      );

  }


  public addItem(type: string, o?: any) {
    const options = this.entry.get(type) as FormArray;
    options.push(this.createItem(o));
  }

  public deleteItem(type: string, i: number) {
    const options = this.entry.get(type) as FormArray;
    options.removeAt(i);
  }

  public getAllPropertyIds() {
    const url = `GET /energystar/properties`;

    this._energyStarMeters.findAllPropertiesFromCompanyUser().subscribe(
      (res: string) => {
        this._logger.info(this.constructorName, url, res);

        this.propertyIds = res;
      },
      (err) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public changeIntegrationEnergyStar() {
    const propertyId = this.entry.controls['propertyId'];
    const meterId = this.entry.controls['meterId'];
    const meterType = this.entry.controls['meterType'];
    const meterUnit = this.entry.controls['meterUnit'];

    const integrateEnergyStar = this.entry.get('integrateEnergyStar').value;

    if (integrateEnergyStar) {
      propertyId.setValidators([Validators.required]);
      meterId.setValidators([Validators.required]);
      meterType.setValidators([]);
      meterUnit.setValidators([]);

    } else {
      this.entry.patchValue({ propertyId: '', meterId: '' });
      propertyId.setValidators([]);
      meterId.setValidators([]);
      meterType.setValidators([Validators.required]);
      meterUnit.setValidators([Validators.required]);

    }

    this.entry.updateValueAndValidity();
  }

  public onPropertyChange(e: any): void {
    const propertyId = e.target.value.split(' ')?.[1];
    if (propertyId) {
      this.getAllMetersByPropertyId(propertyId);
    } else {
      this.energyStarMeters = [];
    }
  }

  public getAllMetersByPropertyId(propertyId: string) {
    const url = `GET /energystar/${propertyId}/meters`;
    this.isLoading = true;

    this._energyStarMeters.findAllMetersByPropertyId(propertyId).subscribe(
      (res: IEnergyStarMeter[]) => {
        this._logger.info(this.constructorName, url, res);
        this.energyStarMeters = res;
        this.entry.patchValue({
          energyStarMeters: res
        });
        this.isLoading = false;
      },
      (err) => {
        this._logger.error(this.constructorName, url, err);
        this.isLoading = false;
      }
    );
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      label: ['', [Validators.required]],
      type: ['', [Validators.required]],
      order: ['', [Validators.required]],
      defaultValue: [''],
      itemOptions: this._fb.array([]),
      meterType: ['', []],
      isValidated: [false, []],
      meterUnit: ['', []],
      startDate: ['', []],
      endDate: ['', []],
      estimation: ['', []],
      itemGroup: this._fb.group({
        id: ['', [Validators.required]]
      }),
      template: this._fb.group({
        id: ['', [Validators.required]]
      }),
      propertyId: ['', []],
      meterId: ['', []],
      integrateEnergyStar: [false, []]
    });
  }
  private createItem(o?: IItemOption) {
    return this._fb.group({
      id: [o?.id || ''],
      label: [o?.label || '', [Validators.required]]
    });
  }

  private getItemGroups() {
    const filters = [
      {
        key: 'pagination',
        value: 'false'
      }
    ];

    const url = `GET /item-groups`;
    this._itemGroups.get(1, filters).subscribe(
      (res: IItemGroup[]) => {
        this._logger.info(this.constructorName, url, res);
        this.itemGroups = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
