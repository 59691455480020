import { Injectable } from '@angular/core';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Filter } from '@shared/models/filter.model';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';
import { MetersService } from '../meters.service';
import { EnergyStarMetersService } from '../energy-star-meters.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationEnergyStarService extends PaginationService<
  any
> {


  private static readonly DEFAULT_SUB_LOCATION_FILTER: IFilter = {
    key: 'location',
    value: null
  };

  private static readonly DEFAULT_PARENT_LOCATION_FILTER: IFilter = {
    key: 'parent',
    value: null
  };

  private _filtersScope: any;

  constructor(_paginable: EnergyStarMetersService, _logger: LoggerService) {
    super(_paginable, _logger);

    this.setFilters([
      PaginationEnergyStarService.DEFAULT_SUB_LOCATION_FILTER,
      PaginationEnergyStarService.DEFAULT_PARENT_LOCATION_FILTER,

    ]);
  }

  public getFilters(): IFilter[] {
    return this._filtersScope;
  }

  public reset() {
    super.reset();

    this.setFilters([
      PaginationEnergyStarService.DEFAULT_SUB_LOCATION_FILTER,
      PaginationEnergyStarService.DEFAULT_PARENT_LOCATION_FILTER,
    ]);
  }

  public setFilters(filters: Partial<IFilter>[]): void {
    this._filtersScope = filters.map((f) => new Filter(f));
    super.setFilters(this._filtersScope);
  }
}
