import { getSerializer } from '@shared/serializers/serializer';
import { templates } from '@shared/serializers/templates.serializer';

export const items = {
  type: 'item',
  attributes: [
    'template',
    'itemGroup',
    'itemOptions',
    'label',
    'type',
    'value',
    'defaultValue',
    'meterType',
    'isValidated',
    'meterUnit',
    'comment',
    'order',
    'meterId',
    'propertyId',
    'createdAt',
    'updatedAt'
  ],
  template: {
    ref: 'id',
    attributes: templates.attributes,
    included: false
  },
  itemGroup: {
    ref: 'id',
    attributes: templates.attributes,
    included: false
  }
};

export const ItemsSerializer = getSerializer(items);
