import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _toasts: ToastService
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._commonEnvironments.getToken();

    if (!!token) {
      const authorization = {
        Authorization: `Bearer ${token}`
      };

      request = request.clone({
        setHeaders: {
          ...authorization
        }
      });
    }

    return next.handle(request).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        // Operation failed; error is an HttpErrorResponse
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            const status = err.status;
            console.log(err);
            if (status === 0 || status == 504) {
              this._toasts.error('Sorry! The server is temporarily unavailable. Please try again later.');
            } else if (status === 401) {
              this._toasts.error("Unauthorized!");
            } else if (status === 403) {
              this._toasts.error("Forbidden! You don't have permission.");
            } else if (status === 500) {
              this._toasts.error("An error has occurred!");
            } else if (status === 429) {
              this._toasts.error("Too many requests, please try again later.");
            }
          }
        }
      )
    );
  }
}
