import { pictures } from '@shared/serializers/pictures.serializer';
import { getSerializer } from '@shared/serializers/serializer';

export const companies = {
  type: 'company',
  attributes: [
    'name',
    'logo',
    'employeesCount',
    'isDeleted',
    'createdAt',
    'updatedAt',
    'companyEnergyStarId'
  ],
  logo: {
    ref: 'id',
    attributes: pictures.attributes,
    included: false
  }
};

export const CompaniesSerializer = getSerializer(companies);
